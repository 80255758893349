import axios from './config'
import {
  USERS_REQUEST,
  USERS_SUCCESS,
  USERS_FAILED,
} from './constans'
import { message } from 'antd'

const userProfileRequest = () => (
  {
    type: USERS_REQUEST,
  }
)

const userProfileSuccess = (data) => (
  {
    type: USERS_SUCCESS,
    data,
  }
)

const userProfileError = (errorMessage) => (
  {
    type: USERS_FAILED,
    errorMessage,
  }
)

export const fetchApprovalUser = (token, param) => {
  return (dispatch, getState) => {
    dispatch(userProfileRequest())

    const urlPage = param ? `/permissions/requests${param}` : '/permissions/requests'
    return new Promise((resolve, reject) => {
      axios.get(
        urlPage,
        { headers: { 'x-api-key': token } },
      ).then((response) => {
        const { data, status } = response
        if (status === 200) {
          dispatch(userProfileSuccess(data.data))
          resolve(data.data)
        }
      }).catch(error => {
        reject(error)
        dispatch(userProfileError(error))
      })
    });
  }
}

export const addUser = (token, payload) => {
  return () => {
    const url = 'permissions/requests'

    return new Promise((resolve, reject) => {
      axios.post(
        url,
        payload,
        { headers: { 'x-api-key': token } },
      ).then((res) => {
        if (res.data.success) {
          resolve(res.data.data)
          message.success(res.data.data)
          // setTimeout(
          //   () => history.push('/approval-permission'),
          //   1000
          // );
        } else {
          message.error('Terdapat kesalahan')
        }
      }).catch((err) => {
        reject(err.message)
      })
    })
  }
}

export const approveRequest = (id, payload, token) => {
  return (dispatch) => {
    const url = `/permissions/requests/approve/${id}`

    return new Promise((resolve, reject) => {
      axios.put(
        url,
        payload,
        { headers: { 'x-api-key': token } },
      ).then((res) => {
        if (res.data.success) {
          resolve(res.data.data)
          dispatch(fetchApprovalUser(token))
        }
      }).catch((err) => {
        reject(err.message)
      })
    })
  }
}

export const rejectRequest = (id, payload, token) => {
  return (dispatch) => {
    const url = `/permissions/requests/reject/${id}`

    return new Promise((resolve, reject) => {
      axios.put(
        url,
        payload,
        { headers: { 'x-api-key': token } },
      ).then((res) => {
        if (res.data.success) {
          resolve(res.data.data)
          dispatch(fetchApprovalUser(token))
        }
      }).catch((err) => {
        reject(err.message)
      })
    })
  }
}

export const fetchUserList = (token, param) => {
  return () => {
    const urlPage = `/users${param || ''}`
    return new Promise((resolve, reject) => {
      axios.get(
        urlPage,
        { headers: { 'x-api-key': token } },
      ).then((response) => {
        const { data, status } = response
        if (status === 200) {
          resolve(data.data)
        }
      }).catch(error => {
        reject(error)
      })
    });
  }
}

export const deleteUser = (token, id) => {
  return () => {
    const urlPage = `/users/delete/${id}`
    return new Promise((resolve, reject) => {
      axios.delete(
        urlPage,
        { headers: { 'x-api-key': token } },
      ).then((response) => {
        const { data, status } = response
        if (status === 200) {
          resolve(data.data)
        }
      }).catch(error => {
        reject(error)
      })
    });
  }
}

export const fetchDetailUser = (token, id) => {
  return () => {

    const urlPage = `/users/detail/${id}`
    return new Promise((resolve, reject) => {
      axios.get(
        urlPage,
        { headers: { 'x-api-key': token } },
      ).then((response) => {
        const { data, status } = response
        if (status === 200) {
          resolve(data.data)
        }
      }).catch(error => {
        reject(error)
      })
    });
  }
}

export const getAuthentication = (token) => {
   return () => {

    const apiUrl = `/keys/validate-token`
    return new Promise((resolve, reject) => {
      axios.get(
        apiUrl,
        { headers: { 'x-api-key': token } },
      ).then((response) => {
        const { data, status } = response
        if (status === 200) {
          resolve(data.data)
        }
      }).catch(error => {
        reject(error)
      })
    });
  }
}

export const updateUserStatus = (token, id, payload) => {
  return () => {
    const url = `/users/update-status/${id}`

    return new Promise((resolve, reject) => {
      axios.put(
        url,
        payload,
        { headers: { 'x-api-key': token } },
      ).then((res) => {
        if (res.data.success) {
          resolve(res.data.data)
        }
      }).catch((err) => {
        reject(err.message)
      })
    })
  }
}

export const fetchDetailPermissionUser = (token, id) => {
  return () => {

    const urlPage = `/permissions/requests/${id}`
    return new Promise((resolve, reject) => {
      axios.get(
        urlPage,
        { headers: { 'x-api-key': token } },
      ).then((response) => {
        const { data, status } = response
        if (status === 200) {
          resolve(data.data)
        }
      }).catch(error => {
        reject(error)
      })
    });
  }
}

export const updateUserPermission = (id, payload, token) => {
  return () => {
    const url = `users/update-permission/${id}`

    return new Promise((resolve, reject) => {
      axios.put(
        url,
        payload,
        { headers: { 'x-api-key': token } },
      ).then((res) => {
        if (res.data.success) {
          resolve(res.data.data)
          message.success(res.data.data)
        }
      }).catch((err) => {
        reject(err.message)
      })
    })
  }
}

export const updatePermissionRequest = (id, payload, token) => {
  return () => {
    const url = `permissions/requests/update/${id}`

    return new Promise((resolve, reject) => {
      axios.put(
        url,
        payload,
        { headers: { 'x-api-key': token } },
      ).then((res) => {
        if (res.data.success) {
          resolve(res.data.data)
          message.success(res.data.data)
        }
      }).catch((err) => {
        reject(err.message)
      })
    })
  }
}

export const resendActivation = (token, payload) => {
  return () => {
    const url = `/users/resend-activation`

    return new Promise((resolve, reject) => {
      axios.post(
          url,
          payload,
          { headers: { 'x-api-key': token } },
      ).then((res) => {
        if (res.data.success) {
          resolve(res.data.data)
        }
      }).catch((err) => {
        reject(err.message)
      })
    })
  }
}
